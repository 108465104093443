import { createRequestUrl } from '../../../../play-core/utils/request-url-helper';
export const PacShowPageSectionsApiPath = '/show-page';
export const loadPacShowPage = async (axios, showUrn, preview, previewDate) => {
  const requestUrl = createRequestUrl(PacShowPageSectionsApiPath, {
    showUrn,
    preview,
    previewDate
  });
  const {
    data
  } = await axios.get(requestUrl);
  return data.data;
};