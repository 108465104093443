import { styled, css } from 'styled-components';
import { Viewports } from '../../../../../play-core/styles/Viewports';
import { PlaySecondaryLink } from '../../../components/ClickableComponents/PlayLink';
export const LinkContainer = styled.ul.withConfig({
  displayName: "ShowLinksstyles__LinkContainer",
  componentId: "sc-1oot746-0"
})(["display:flex;flex-wrap:wrap;gap:8px;width:100%;"]);
const buttonStyle = css(["", "{width:100%;}"], Viewports.for('small'));
export const StyledListItem = styled.li.withConfig({
  displayName: "ShowLinksstyles__StyledListItem",
  componentId: "sc-1oot746-1"
})(["", ""], buttonStyle);
export const StyledLink = styled(PlaySecondaryLink).withConfig({
  shouldForwardProp: () => true
}).withConfig({
  displayName: "ShowLinksstyles__StyledLink",
  componentId: "sc-1oot746-2"
})(["", ""], buttonStyle);