import { ClientMetaFunction, mergeMeta } from '@app/utils/remix/client-meta';
import { scaleImageSource } from '@play-now/core/utils/image-helper';
import { loadShowDetail } from '@play-now/video/api/load-show-detail';
import { aspectRatioMap } from '@play-now/video/components/Image/PlayImage';
import { metaImageWidth } from '@play-now/video/components/Meta/Meta.config';
import { getRemixMeta } from '@play-now/video/components/Meta/PlayMetaData';
import { toTVSeriesSchema } from '@play-now/video/components/PageMetaData/JsonLd';
import { useInitialData } from '@play-now/video/config/VideoAppContext';
import { notFoundPath } from '@play-now/video/pages/Error/PageNotFound.route';
import { ShowPage } from '@play-now/video/pages/Show/ShowPage';
import { showPath } from '@play-now/video/pages/Show/ShowPage.route';
import { json, LoaderFunctionArgs, redirect } from '@remix-run/node';
import { useLoaderData } from '@remix-run/react';

import { PlayLayout } from './PlayLayout';
import { getRedirectRoute, setupPlayUtils } from './remix-route-helper';

export const loader = async (args: LoaderFunctionArgs) => {
  const {
    query,
    axios,
    config,
    i18n,
    request: { url },
  } = setupPlayUtils(args);
  const showId = query.id;
  // Redirect to 404 page if no showId is provided
  if (!showId?.length) return redirect(config.baseUrl + notFoundPath());

  const show = await loadShowDetail(showId, axios);

  // Redirect to show page page with full path if needed
  const redirectResponse = getRedirectRoute({ fullPath: config.baseUrl + showPath(i18n, show), url });
  if (redirectResponse) return redirectResponse;

  //  Get the meta data
  const showMeta = getRemixMeta({
    config,
    title: show.title,
    description: show.description,
    image: scaleImageSource(show.imageUrl, metaImageWidth, 'jpg', config.modernImagesBaseUrl),
    imageWidth: metaImageWidth,
    imageHeight: metaImageWidth / aspectRatioMap['16/9'],
    type: 'video.tv_show',
    'script:ld+json': toTVSeriesSchema(show, i18n, config.language, config.baseUrl, config.modernImagesBaseUrl),
  });

  return json({ show, meta: showMeta });
};

export const meta: ClientMetaFunction<typeof loader> = ({ data, matches }) => mergeMeta({ data, matches });

// Exporting an empty client loader enables instant client-side navigation.
export const clientLoader = async () => ({ show: undefined });

const ShowPageRoute = () => {
  const { show } = useLoaderData<typeof loader>();
  const initialData = useInitialData();
  return (
    <PlayLayout pageId='show'>
      <ShowPage initialData={{ ...initialData, show }} />
    </PlayLayout>
  );
};

export default ShowPageRoute;
