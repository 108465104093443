import { useQuery } from '@tanstack/react-query';
import { loadPacShowPage } from '../../api/pac/load-pac-show-page';
import { queryKeys } from '../../config/use-query';
import { useVideoConfig } from '../../config/VideoAppContext';
import { useAxios } from '../../hooks/use-axios';
export const useShowSections = (initialData, showUrn) => {
  const axios = useAxios();
  const {
    usePreview,
    previewDate
  } = useVideoConfig();
  const initialShowPage = initialData.pacPageConfigs?.showPages?.[showUrn ?? ''];
  const shouldLoadShowPage = getSections(initialShowPage).length < 1;
  const {
    data: showPage,
    isLoading: sectionsLoading
  } = useQuery({
    queryKey: [queryKeys.PacShowPage(showUrn ?? ''), {
      usePreview,
      previewDate
    }, axios],
    queryFn: () => loadPacShowPage(axios, showUrn ?? '', usePreview, previewDate),
    enabled: shouldLoadShowPage,
    initialData: initialShowPage
  });
  return {
    showSections: getSections(showPage),
    sectionsLoading
  };
};
const getSections = page => page?.sections ?? [];