import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { isBrowser } from '../../../play-core/utils/ssr-helper';
const useIsomorphicLayoutEffect = isBrowser ? useLayoutEffect : useEffect;
export const useElementSize = (ref, debounceDelay = 0) => {
  const [size, setSize] = useState(getElementSize(ref.current));
  const handleResize = useCallback(() => {
    if (ref.current) {
      setSize(getElementSize(ref.current));
    }
  }, [ref]);
  const debouncedHandleResize = useDebouncedCallback(handleResize, debounceDelay);
  useIsomorphicLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    handleResize();
    window.addEventListener('resize', () => {
      debouncedHandleResize();
    });
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [debouncedHandleResize, handleResize, ref]);
  return size;
};
const getElementSize = element => {
  if (!element) {
    return {
      width: 0,
      height: 0
    };
  }
  return {
    width: element.offsetWidth,
    height: element.offsetHeight
  };
};